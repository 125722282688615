import React, { useState, useEffect, useRef } from 'react';

// DataRecommender 예시
// DataRecommender = [
//     {
//         symbol: 'BTCUSDT',
//         buy: 0.3,
//         sell: 0.1,
//         neutral: 0.6
//     },
//     ...
// ]

function CompoRecommender({DataRecommender}) {
    const render = DataRecommender && DataRecommender.map(
        function (report, index) {
            const symbol = report['symbol'];
            const buy = report['buy'];
            const sell = report['sell'];
            const neutral = report['neutral'];
            const diff = buy-sell;

            return (
                <tr key={index}>
                    <td>{symbol}</td>
                    <td>{buy.toFixed(2)}</td>
                    <td>{sell.toFixed(2)}</td>
                    <td>{neutral.toFixed(2)}</td>
                    <td>{diff.toFixed(2)}</td>
                </tr>
            );    

        }
    );

    return (
        <div style={{margin:'10px'}}>
            <table>
                <thead>
                    <tr>
                        <th> Symbol </th>
                        <th> Buy </th>
                        <th> Sell </th>
                        <th> Neutral</th>
                        <th> diff </th>
                    </tr>
                </thead>
                <tbody>
                    {render}
                </tbody>
            </table>
        </div>
    )
}

export default CompoRecommender
