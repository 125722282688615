import './mytable.css';
import React, { useState, useEffect, useRef } from 'react';
import { Row, Col, Button, Input, Select } from 'antd';
import axios from "axios";
import { Switch } from 'antd';

function CompoPositionsList({DataPositionsList, updatePositionsList}) {   
    console.log('CompoPositionsList start');
    
    async function onClickMarket(clientOrderId){
        console.log('onClickMarket', clientOrderId);
        const res = await axios.post("/api/exit_order_with_market", {clientOrderId});     // clientOrderId 로 enter한 position은 모두 market으로 정리함
        if (res.data.success) {
            console.log('exit_order_with_market ok')
        } else {
            alert('Error:exit_order_with_market:'+res.data.errmsg);
        }
    }



    function formatNumber(value) {
        return Math.abs(value) > 1 ? value.toFixed(1) : value.toFixed(4);
    }

    const render = DataPositionsList && DataPositionsList.map( function (positions, index) {
        console.log("positions==>", positions);
        return (
            <tr key={index}>
                <td>{positions.symbol}</td>
                <td>{formatNumber(positions.signed_qty)}</td>
                <td>{formatNumber(positions.entry_price)}</td>
                <td>{formatNumber(positions.curr_price)}</td>
                <td>{formatNumber(positions.pnl)}</td>
                <td>{formatNumber(positions.pnl_pct)}</td>
                <td>
                    <Button onClick={()=>onClickMarket(positions.clientOrderId)}>Market</Button>
                </td>
            </tr>
        )}
    )
    

    return (
        <div style={{margin:'15px'}}>
            <table className='mytable'>
                <thead>
                    <tr>
                        <th>Symbol</th>
                        <th>Signed Qty</th>
                        <th>Entry Price</th>
                        <th>Curr Price</th>
                        <th>PnL</th>
                        <th>PnL(%)</th>
                        <th><Button onClick={updatePositionsList}>Update</Button></th>
                    </tr>
                </thead>
                <tbody>
                    {render}
                </tbody>
            </table>
        </div>
    )
}

export default CompoPositionsList
